import { logError } from "@telia/b2x-logging";

import {
  fetchTscidForOrganizationNumber,
  getScopeFromUrl,
  isEmpty,
  isOrgNumberSerialCustomerId,
  removeInitialLetter,
  scopeIdIsInvalid,
} from "./util";


export {
  getScopeFromUrl,
};

export async function getScopeId(url: string = window.location.pathname): Promise<string | null> {
  const scopeId = getScopeFromUrl(url);
  if (scopeIdIsInvalid(scopeId)) return null;
  if (isOrgNumberSerialCustomerId(scopeId!)) {
    logError('b2b-customer-scope', 'Warning: getScopeId passed a scope which isOrgNumberSerialCustomerId.');
    const organizationNumber = removeInitialLetter(scopeId!);
    return fetchTscidForOrganizationNumber(organizationNumber);
  }
  return scopeId;
}

export async function getScopeIdOrThrow(url: string = window.location.pathname): Promise<string> {
  const scopeId = getScopeFromUrl(url);
  if (scopeIdIsInvalid(scopeId)) {
    throw new Error("INVALID_SCOPE_IN_URL");
  }

  if (isOrgNumberSerialCustomerId(scopeId!)) {
    logError('b2b-customer-scope', 'Warning: getScopeIdOrThrow passed a scope which isOrgNumberSerialCustomerId.');
    const organizationNumber = removeInitialLetter(scopeId!);
    const tscid = await fetchTscidForOrganizationNumber(organizationNumber);

    if (isEmpty(tscid)) {
      throw new Error("COULD_NOT_GET_TSCID");
    }

    return tscid!;
  }

  return scopeId!;
}

export function scopeIdIsAccessProfileId(scopeId: string | null | undefined): boolean {
  return /^8\d{8}$/.test(scopeId ?? "");
}

export function urlContainsScope(path: string) {
  return getScopeFromUrl(path) !== null;
}
